/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Menu, Dropdown } from "antd";
import { connect } from "react-redux";
import {
	LogoutOutlined,
	UserOutlined,
	ClearOutlined,
	SwapOutlined,
} from "@ant-design/icons";
import Icon from "components/util-components/Icon";
import { signOut } from "redux/actions/Auth";
import { setCarregador } from "redux/actions/Carregador";
import requestCacheService from "services/RequestCacheService";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { APP_PREFIX_PATH } from "configs/AppConfig";

const menuItem = [];

export const NavProfile = ({ signOut, user, carregador, setCarregador }) => {
	const history = useHistory();

	const trocarCarregador = async () => {
		setCarregador(null, null);
		history.push(`${APP_PREFIX_PATH}/escolha-carregador`);
	};

	const abrirTelaDadosConta = async () => {
		history.push(`${APP_PREFIX_PATH}/dados-conta`);
	};

	const profileMenu = (
		<div className="nav-profile nav-dropdown">
			<div className="nav-profile-header">
				<div className="d-flex">
					<UserOutlined style={{ fontSize: 45 }} />
					<div className="pl-3">
						<h4 className="mb-0">{user.name}</h4>
						<span className="text-muted">{user.role}</span>
						{carregador.id &&
							carregador.numero_serie &&
							carregador.numero_serie !== "null" && (
								<div className="text-muted">
									Carregador {carregador.numero_serie}
								</div>
							)}
					</div>
				</div>
			</div>
			<div className="nav-profile-body">
				<Menu>
					{menuItem.map((el, i) => {
						return (
							<Menu.Item key={i}>
								<a href={el.path}>
									<Icon className="mr-3" type={el.icon} />
									<span className="font-weight-normal">
										{el.title}
									</span>
								</a>
							</Menu.Item>
						);
					})}
					<Menu.Item key={0}>
						<a onClick={() => requestCacheService.limparCache()}>
							<ClearOutlined className="mr-3" />
							<span className="font-weight-normal">
								Limpar cache
							</span>
						</a>
					</Menu.Item>
					<Menu.Item key={1}>
						<a onClick={() => trocarCarregador()}>
							<SwapOutlined className="mr-3" />
							<span className="font-weight-normal">
								Trocar carregador
							</span>
						</a>
					</Menu.Item>
					<Menu.Item key={2}>
						<a onClick={() => abrirTelaDadosConta()}>
							<UserOutlined className="mr-3" />
							<span className="font-weight-normal">Conta</span>
						</a>
					</Menu.Item>
					<Menu.Item key={3} onClick={(e) => signOut()}>
						<span>
							<LogoutOutlined className="mr-3" />
							<span className="font-weight-normal">Sair</span>
						</span>
					</Menu.Item>
				</Menu>
			</div>
		</div>
	);
	return (
		<Dropdown
			placement="bottomRight"
			overlay={profileMenu}
			trigger={["click"]}
		>
			<Menu className="d-flex align-item-center" mode="horizontal">
				<Menu.Item key="profile">
					<UserOutlined className="nav-icon mr-0" />
				</Menu.Item>
			</Menu>
		</Dropdown>
	);
};

const mapStateToProps = ({ user, carregador }) => {
	return { user: { ...user }, carregador: { ...carregador } };
};

export default connect(mapStateToProps, { signOut, setCarregador })(NavProfile);
