import { UPDATE_CARREGADOR } from "redux/constants/Carregador";

const initCarregador = {
	id: localStorage.getItem("carregador_id"),
	numero_serie: localStorage.getItem("carregador_numero_serie"),
};

const carregador = (state = initCarregador, action) => {
	switch (action.type) {
		case UPDATE_CARREGADOR:
			localStorage.setItem("carregador_id", action.id);

			localStorage.setItem(
				"carregador_numero_serie",
				action.numero_serie,
			);

			return {
				...state,
				id: action.id,
				numero_serie: action.numero_serie,
			};
		default:
			return state;
	}
};

export default carregador;
