// TODO: após clonar o skeleton, ajustar os links de ambientes

const dev = {
	API_ENDPOINT_URL: "http://localhost:8080/intelligenz/api",
	PUBLIC_PREFIX_PATH: "",
	API_SOCKET_ENDPOINT_URL: "http://localhost:8080/",
	API_SOCKET_SUFIX_PATH: "/socket.io",
};

const prod = {
	API_ENDPOINT_URL:
		"https://intelligenz-back-re9ar.ondigitalocean.app/intelligenx-back2/intelligenz/api",
	PUBLIC_PREFIX_PATH: "",
	API_SOCKET_ENDPOINT_URL:
		"https://intelligenz-back-re9ar.ondigitalocean.app/",
	API_SOCKET_SUFIX_PATH: "/intelligenx-back2/intelligenz/socket.io",
};

const test = {
	API_ENDPOINT_URL: "https://homolog.dimo.com.br/intelligenz-back/api",
	PUBLIC_PREFIX_PATH: "/intelligenz",
	API_SOCKET_ENDPOINT_URL: "https://homolog.dimo.com.br/",
	API_SOCKET_SUFIX_PATH: "/intelligenz-back/socket.io",
};

const getEnv = () => {
	switch (process.env.REACT_APP_ENV) {
		case "development":
			return dev;
		case "production":
			return prod;
		case "test":
			return test;
		default:
			return dev;
	}
};

export const env = getEnv();
