import {
	HomeOutlined,
	TeamOutlined,
	UsergroupAddOutlined,
	ShopOutlined,
	ClusterOutlined,
	DesktopOutlined,
	ThunderboltOutlined,
	EyeOutlined,
} from "@ant-design/icons";
import { BatterySvg } from "assets/svg/icon";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import {
	PermissaoCarregadorEnum,
	PermissaoCentroDistribuicaoEnum,
	PermissaoClienteEnum,
	PermissaoGrupoUsuarioEnum,
	PermissaoModeloBateriaEnum,
	PermissaoTesteBateriaEnum,
	PermissaoUsuarioEnum,
} from "enum/permissao-enum";

const dashBoardNavTree = [
	{
		key: "dashboard",
		path: `${APP_PREFIX_PATH}/dashboard`,
		title: "dashboard",
		icon: HomeOutlined,
		breadcrumb: false,
		submenu: [],
	},
	{
		key: "dashboard-teste-bateria",
		path: `${APP_PREFIX_PATH}/dashboard-teste-bateria`,
		title: "dashboard-teste-bateria",
		icon: ThunderboltOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: [PermissaoTesteBateriaEnum.REALIZAR_TESTE_BATERIA],
	},
	{
		key: "logs",
		path: `${APP_PREFIX_PATH}/logs`,
		title: "logs",
		icon: EyeOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: [PermissaoTesteBateriaEnum.REALIZAR_TESTE_BATERIA],
	},
	{
		key: "lista-usuarios",
		path: `${APP_PREFIX_PATH}/lista-usuarios`,
		title: "lista-usuarios",
		icon: TeamOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: [
			PermissaoUsuarioEnum.GERENCIAR_USUARIO_PARA_QUALQUER_CLIENTE,
			PermissaoUsuarioEnum.GERENCIAR_USUARIO_PARA_QUALQUER_CENTRO_DISTRIBUICAO,
			PermissaoUsuarioEnum.GERENCIAR_USUARIO_APENAS_PROPRIO_CENTRO_DISTRIBUICAO,
		],
	},
	{
		key: "lista-grupos-usuario",
		path: `${APP_PREFIX_PATH}/lista-grupos-usuario`,
		title: "lista-grupos-usuario",
		icon: UsergroupAddOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: PermissaoGrupoUsuarioEnum.VISUALIZAR,
	},
	{
		key: "lista-clientes",
		path: `${APP_PREFIX_PATH}/lista-clientes`,
		title: "lista-clientes",
		icon: ClusterOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: PermissaoClienteEnum.VISUALIZAR,
	},
	{
		key: "lista-centros-distribuicao",
		path: `${APP_PREFIX_PATH}/lista-centros-distribuicao`,
		title: "lista-centros-distribuicao",
		icon: ShopOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: [
			PermissaoCentroDistribuicaoEnum.GERENCIAR_CENTRO_DISTRIBUICAO_PARA_QUALQUER_CLIENTE,
			PermissaoCentroDistribuicaoEnum.GERENCIAR_CENTRO_DISTRIBUICAO_APENAS_PROPRIO_CLIENTE,
		],
	},
	{
		key: "lista-carregadores",
		path: `${APP_PREFIX_PATH}/lista-carregadores`,
		title: "lista-carregadores",
		icon: DesktopOutlined,
		breadcrumb: false,
		submenu: [],
		permissao: [
			PermissaoCarregadorEnum.GERENCIAR_CARREGADOR_PARA_QUALQUER_CLIENTE,
			PermissaoCarregadorEnum.GERENCIAR_CARREGADOR_APENAS_PROPRIO_CLIENTE,
			PermissaoCarregadorEnum.VISUALIZAR_PROPRIO_CENTRO,
		],
	},
	{
		key: "lista-modelos-bateria",
		path: `${APP_PREFIX_PATH}/lista-modelos-bateria`,
		title: "lista-modelos-bateria",
		icon: BatterySvg,
		breadcrumb: false,
		submenu: [],
		permissao: [
			PermissaoModeloBateriaEnum.GERENCIAR_MODELO_BATERIA_PARA_QUALQUER_CLIENTE,
			PermissaoModeloBateriaEnum.GERENCIAR_MODELO_BATERIA_APENAS_PROPRIO_CLIENTE,
		],
	},
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
