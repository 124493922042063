export const PermissaoUsuarioEnum = {
	GERENCIAR_USUARIO_PARA_QUALQUER_CLIENTE:
		"GERENCIAR_USUARIO_PARA_QUALQUER_CLIENTE",
	GERENCIAR_USUARIO_PARA_QUALQUER_CENTRO_DISTRIBUICAO:
		"GERENCIAR_USUARIO_PARA_QUALQUER_CENTRO_DISTRIBUICAO",
	GERENCIAR_USUARIO_APENAS_PROPRIO_CENTRO_DISTRIBUICAO:
		"GERENCIAR_USUARIO_APENAS_PROPRIO_CENTRO_DISTRIBUICAO",
};

export const PermissaoGrupoUsuarioEnum = {
	VISUALIZAR: "VISUALIZAR_GRUPO_USUARIO",
	CRIAR: "CRIAR_GRUPO_USUARIO",
	EXCLUIR: "EXCLUIR_GRUPO_USUARIO",
};

export const PermissaoClienteEnum = {
	VISUALIZAR: "VISUALIZAR_CLIENTE",
	CRIAR: "CRIAR_CLIENTE",
	EXCLUIR: "EXCLUIR_CLIENTE",
};

export const PermissaoCentroDistribuicaoEnum = {
	GERENCIAR_CENTRO_DISTRIBUICAO_PARA_QUALQUER_CLIENTE:
		"GERENCIAR_CENTRO_DISTRIBUICAO_PARA_QUALQUER_CLIENTE",
	GERENCIAR_CENTRO_DISTRIBUICAO_APENAS_PROPRIO_CLIENTE:
		"GERENCIAR_CENTRO_DISTRIBUICAO_APENAS_PROPRIO_CLIENTE",
};

export const PermissaoModeloBateriaEnum = {
	GERENCIAR_MODELO_BATERIA_PARA_QUALQUER_CLIENTE:
		"GERENCIAR_MODELO_BATERIA_PARA_QUALQUER_CLIENTE",
	GERENCIAR_MODELO_BATERIA_APENAS_PROPRIO_CLIENTE:
		"GERENCIAR_MODELO_BATERIA_APENAS_PROPRIO_CLIENTE",
};

export const PermissaoCarregadorEnum = {
	GERENCIAR_CARREGADOR_PARA_QUALQUER_CLIENTE:
		"GERENCIAR_CARREGADOR_PARA_QUALQUER_CLIENTE",
	GERENCIAR_CARREGADOR_APENAS_PROPRIO_CLIENTE:
		"GERENCIAR_CARREGADOR_APENAS_PROPRIO_CLIENTE",
	VISUALIZAR_PROPRIO_CENTRO: "VISUALIZAR_PROPRIO_CENTRO",
};

export const PermissaoTesteBateriaEnum = {
	REALIZAR_TESTE_BATERIA: "REALIZAR_TESTE_BATERIA",
};
